@import '~@ebay/skin/tokens.css';
.vas-tire-installation__installation_v2 .installer-card .installer-card__name {
  line-height: 24px;
  font-size: 0.875rem;
}
.vas-tire-installation__installation_v2 .installer-card .installer-card__dropoff__date-time {
  font-size: 0.75rem;
  line-height: 16px;
}
.vas-tire-installation__installation_v2 .installer-card .installer-card__hours {
  margin: 3px 0 8px 0;
}
.vas-tire-installation__installation_v2 .installer-card .installer-card__dropoff__date-time:last-child span {
  font-weight: 700;
}
.vas-tire-installation__installation_v2 .installer-card .installer-card__accessory-label {
  font-size: 0.75rem;
}
.vas-tire-installation__installation_v2 .installer-card .installer-card__accessory-label .ux-textspans--BOLD:first-child {
  font-weight: 700;
}
.vas-tire-installation__installation_v2 .installer-card .installer-card__index {
  display: none;
}
.vas-tire-installation__installation_v2 .installer-card .ux-textspans--BOLD {
  font-weight: 400;
}
.installer-card .installer-card__address span {
  display: block;
  font-size: var(--font-size-small);
  line-height: 16px;
}
.installer-card__dropoff__date-time span {
  display: block;
}
.installer-card__columns {
  display: flex;
  justify-content: space-between;
}
.installer-card__name {
  font-size: var(--font-size-medium);
  line-height: 24px;
}
.installer-card__accessory-label {
  padding: 0 8px;
  text-align: right;
  white-space: nowrap;
}
.installer-card__accessory-label span:first-child span {
  color: var(--color-foreground-primary);
  font-weight: normal;
}
.installer-card__accessory-label span {
  color: var(--color-foreground-secondary);
  display: block;
  font-size: 0.75rem;
  line-height: 20px;
}
.installer-card__hours {
  margin: 8px 0;
  display: block;
  font-size: var(--font-size-small);
  line-height: 16px;
  color: var(--color-foreground-secondary);
}
.installer-card__hours span {
  display: block;
}
.installer-card__hours-title {
  display: none;
}
.installer-card__dropoff {
  padding-top: 8px;
}
.installer-card-no-radio {
  display: flex;
  align-items: flex-start;
  text-align: left;
  position: relative;
  width: 345px;
}
.installer-card-no-radio__left {
  margin-right: 8px;
  margin-top: 8px;
  width: 80px;
  height: 80px;
}
.installer-card-no-radio__left img {
  width: 80px;
  height: 80px;
  border-radius: 16px;
}
.installer-card-no-radio__name,
.installer-card-no-radio__address span {
  font-size: var(--font-size-medium);
  line-height: 24px;
}
.installer-card-no-radio__name,
.installer-card-no-radio__dropoff__date-time,
.installer-card-no-radio .star-rating-average {
  display: block;
}
.installer-card-no-radio__star-rating,
.installer-card-no-radio__accessory-label {
  display: flex;
  align-items: center;
}
.installer-card-no-radio__star-rating-average {
  margin-left: 8px;
}
.installer-card-no-radio__star-rating-average-pre-fix {
  margin-right: 8px;
}
.installer-card-no-radio__star-rating-average-pre-fix,
.installer-card-no-radio__star-rating-average {
  font-size: var(--font-size-default);
  line-height: 20px;
  color: var(--color-foreground-secondary);
}
.installer-card-no-radio__accessory-label {
  padding: 0 8px;
  flex-wrap: wrap;
  white-space: pre-wrap;
  font-size: var(--font-size-small);
  line-height: 16px;
  color: var(--color-foreground-secondary);
  margin-bottom: 10px;
}
.installer-card-no-radio__accessory-label span {
  display: inline-block;
  padding-right: 4px;
  font-weight: normal;
}
.installer-card-no-radio__hours {
  margin: 8px 0;
  display: block;
  font-size: var(--font-size-small);
  line-height: 16px;
  color: var(--color-foreground-secondary);
}
.installer-card-no-radio__hours span {
  display: block;
}
.installer-card-no-radio__hours-title {
  display: none;
}
.installer-card-no-radio__dropoff {
  padding-top: 8px;
}
.installer-card-no-radio__dropoff__date-time {
  font-size: var(--font-size-small);
  line-height: 16px;
}
.installer-card-no-radio__feature {
  margin-left: 8px;
}
.installer-card-no-radio__name,
.installer-card-no-radio__address,
.installer-card-no-radio__star-rating,
.installer-card-no-radio__accessory-label {
  padding: 4px 0 0;
}
